import React from 'react';
import '../../App.css';
import './Services.css';
import { Slideshow } from './Slideshow';


function Services() {
  return(
    <div className='backstory-pg'>
      <Slideshow className='slideshow'></Slideshow>
    </div>
    
  );
}

export default Services;